:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.main {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: var(--dark);
  line-height: 1.5;
}

.minNav {
  background-color: var(--mainColor);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0rem 2rem;
}

#nav2 {
  display: none;
}

.minNav a {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  color: var(--white);
  opacity: 0.5;
  font-size: 1.2rem;
  transition: 0.25s;
}

.minNav a:hover {
  opacity: 0.8;
}
