body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}
.children_childrenWrapper__ei6lB {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.children_childCardHolder__UC7fM {
  padding-top: 7rem;
  padding-bottom: 1.5rem;
}

.children_addChildrenContainer__3KD0k {
  padding: 2rem;
  padding-top: 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.children_addChildrenButton__2t3_z {
  background-color: #fcb040;
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.children_addChildrenButton__2t3_z:hover {
  background-color: #25aae2;
  background-color: var(--subColor);
  color: #fff;
  color: var(--white);
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.childCard_childCard__mdV91 {
  border-radius: 0px;
  margin-top: 0rem;
  margin-bottom: 0rem;
  text-align: center;
  padding-bottom: 0rem;
  height: 100%;
}
.childCard_childCardSponsored__zRP1L {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.childCard_childCardImage__2aeF3 {
  border-radius: 50%;
  margin-top: -100px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.childCard_childCardTitle__2682b {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 1.2;
}

.childCard_childCardStory__2hYjt {
  display: block;
  background-color: transparent;
  color: inherit;
  border: none;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 1rem;
}
.childCard_childCardStory__2hYjt:hover {
  background-color: transparent;
  text-decoration: underline;
  color: rgb(101, 22, 14);
  color: var(--mainbg);
}

.childCard_storyIcon__12xpK {
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  padding-right: 0.5rem;
}

.childCard_childCardButton__2E3XW,
.childCard_editAChildButton__w7Yns,
.childCard_deleteAChildButton__3LNGN {
  background-color: #fcb040;
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
}
.childCard_childCardButton__2E3XW:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  color: #fff;
  color: var(--white);
}

.childCard_editAChildButton__w7Yns {
  background-color: rgba(101, 23, 14, 0.619);
  background-color: var(--mainbgLight);
}

.childCard_sponsorStatus__Cejf8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.childCard_sponsorStatus__Cejf8 input {
  margin-right: 0.5rem;
  accent-color: rgb(101, 22, 14);
  accent-color: var(--mainbg);
}

.childCard_editAChildButton__w7Yns:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  color: #fff;
  color: var(--white);
}
.childCard_deleteAChildButton__3LNGN {
  background-color: #e44245a1;
  background-color: var(--subColorLight);
}
.childCard_deleteAChildButton__3LNGN:hover {
  background-color: #25aae2;
  background-color: var(--subColor);
  color: #fff;
  color: var(--white);
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.spinner_donut__BRjsa {
  width: 3rem;
  height: 3rem;
  margin: 2rem auto;
  border-radius: 50%;
  border: 0.3rem solid #e44245a1;
  border: 0.3rem solid var(--subColorLight);
  border-top-color: #fcb040;
  border-top-color: var(--mainColor);
  -webkit-animation-name: spinner_spin__KPq0u;
          animation-name: spinner_spin__KPq0u;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: cubic-bezier(0.69, 0.12, 0.83, 0.67);
          animation-timing-function: cubic-bezier(0.69, 0.12, 0.83, 0.67);
}

@-webkit-keyframes spinner_spin__KPq0u {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner_spin__KPq0u {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.childStory_childStoryContainer__2FRVn {
  background-color: #fff;
  background-color: var(--white);
  max-width: 800px;
  margin: 4.6875rem auto;
  text-align: start;
  padding: 2rem 3rem;
  padding-bottom: 4rem;
  position: relative;
}

.childStory_closeStory__1_fUX {
  position: absolute;
  font-size: 2.5rem;
  right: 3rem;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  color: #25aae2;
  color: var(--subColor);
  transition: 0.3s;
  cursor: pointer;
  z-index: 2;
  padding: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: none;
  background-color: transparent;
}

.childStory_closeStory__1_fUX:hover {
  color: #fcb040;
  color: var(--mainColor);
}

.childStory_storyBody__3IgRy {
  position: relative;
  padding-bottom: 2rem;
}

.childStory_storyBody__3IgRy p {
  white-space: pre-wrap;
}

.childStory_storyBody__3IgRy:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  background-image: linear-gradient(
    90deg,
    #a8d064,
    #a8d064 14.2857%,
    #3cab1a 0,
    #3cab1a 28.5714%,
    #008c15 0,
    #008c15 42.8571%,
    #00630f 0,
    #00630f 57.1428%,
    #005ca7 0,
    #005ca7 71.4285%,
    #003a88 0,
    #003a88 85.7142%,
    #00155c 0
  );
}

.childStory_storyHeader__rhxj2 {
  margin-bottom: 1rem;
  padding-bottom: 1.25rem;
  position: relative;
  margin-bottom: 2rem;
}

.childStory_storyHeader__rhxj2::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #a8d064,
    #a8d064 14.2857%,
    #3cab1a 0,
    #3cab1a 28.5714%,
    #008c15 0,
    #008c15 42.8571%,
    #00630f 0,
    #00630f 57.1428%,
    #005ca7 0,
    #005ca7 71.4285%,
    #003a88 0,
    #003a88 85.7142%,
    #00155c 0
  );
}

.childStory_storyImageWrapper__3Y8f_ {
  padding-right: 1rem;
}

.childStory_childCardImage__2wlWf {
  border-radius: 50rem;
}

.childStory_aboutChild__2xTX8 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.childStory_aboutChild__2xTX8 h3 {
  font-size: 2rem;
}

.childStory_aboutChild__2xTX8 span {
  font-weight: 700;
}

.childStory_rightAboutChild__33RQM p {
  padding-right: 1rem;
  margin-bottom: 7px;
}
.childStory_rightAboutChild__33RQM p span {
  color: rgb(101, 22, 14);
  color: var(--mainbg);
}
.childStory_childId__3uAFU {
  color: #ccc;
}
.childStory_sponsor__1DGuk {
  width: 100%;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.childStory_childCardButton__C-GDK {
  background-color: #fcb040;
  background-color: var(--mainColor);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 50rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
  color: var(--white);
}
.childStory_childCardButton__C-GDK:hover {
  background-color: #25aae2;
  background-color: var(--subColor);
  color: #fff;
  color: var(--white);
}

@media (max-width: 767.98px) {
  .childStory_childStoryContainer__2FRVn {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .childStory_closeStory__1_fUX {
    right: 1.5rem;
  }
  .childStory_storyImageWrapper__3Y8f_ {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .childStory_childCardImage__2wlWf {
    height: 200px;
    width: 200px;
  }
  .childStory_aboutChild__2xTX8 {
    text-align: center;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.removeachild_removeContainer__hzCw- {
  background-color: #fff;
  background-color: var(--white);
  padding: 5rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30%;
  display: flex;
}

.removeachild_buttons__27CdV {
  display: flex;
  justify-content: space-around;
}
.removeachild_cancelDeleteButton__3HYui,
.removeachild_deleteAChildButton__sxqk3 {
  background-color: rgba(101, 23, 14, 0.619);
  background-color: var(--mainbgLight);
  border: none;
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.375rem 1.5rem;
  border-radius: 50rem;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  color: #fff;
  color: var(--white);
  display: block;
  margin-bottom: 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s;
  margin: 1rem;
}
.removeachild_cancelDeleteButton__3HYui:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  color: #fff;
  color: var(--white);
}
.removeachild_deleteAChildButton__sxqk3 {
  background-color: #e44245a1;
  background-color: var(--subColorLight);
}
.removeachild_deleteAChildButton__sxqk3:hover {
  background-color: #25aae2;
  background-color: var(--subColor);
  color: #fff;
  color: var(--white);
}

.overlay_overlay__sDWyA {
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.48);
  position: fixed;
  overflow-y: scroll;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}

.childrenPage_childrenPageHeader__1-A2H {
  padding-top: 5rem;
  padding-bottom: 7rem;
}

.childrenPage_headerImageWrapper__a5ZZw {
  padding: 1rem;
  position: relative;
}

.childrenPage_headerImageWrapper__a5ZZw img {
  width: 100%;
}

.childrenPage_headerImageWrapper__a5ZZw:after {
  position: absolute;
  content: '';
  height: 60%;
  width: 60%;
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px 0px 10px 0px;
}
.childrenPage_headerImageWrapper__a5ZZw:before {
  position: absolute;
  content: '';
  height: 100%;
  width: 60%;
  background-color: #fcb040;
  background-color: var(--mainColor);
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px 0px 0px 10px;
}

.childrenPage_headerText__2FGyf {
  height: 100%;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 2rem;
}

@media (max-width: 991.98px) {
  .childrenPage_childrenPageHeader__1-A2H {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .childrenPage_headerImageWrapper__a5ZZw {
    margin-bottom: 2rem;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.sponsorPage_sponsorPage__sxB4m {
  background-color: #f3f0e9;
  padding-top: 5rem;
  text-align: center;
  top: 0;
}

.sponsorPage_sponsorHeadWelcome__EUfaN {
  font-weight: bold;
  margin: 2rem auto;
}

.sponsorPage_article__2pB4W {
  width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.sponsorPage_childDetails__2Q_eT,
.sponsorPage_contactInformation__30xQ_ {
  padding: 3rem 2rem;
  background-color: #fff;
  background-color: var(--white);
  position: relative;
  margin-bottom: 2rem;
}
.sponsorPage_childDetails__2Q_eT .sponsorPage_row__3_R2L {
  display: flex;
}

.sponsorPage_childDetails__2Q_eT:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.sponsorPage_childImageHolder__1_J4s {
  padding: 0rem 1rem;
  max-width: 250px;
}
.sponsorPage_childImageHolder__1_J4s img {
  border-radius: 50rem;
  width: 100%;
}

.sponsorPage_childDetailsHolder__HJdGA {
  text-align: start;
}

.sponsorPage_childDetailsHolder__HJdGA h3 span {
  font-weight: bold;
}

.sponsorPage_childDetailsHolder__HJdGA h3 {
  font-size: 2.1rem;
}

.sponsorPage_childCardStory__1lK-Z {
  display: block;
  background-color: transparent;
  color: inherit;
  border: none;

  padding-bottom: 1rem;
}
.sponsorPage_childCardStory__1lK-Z:hover {
  background-color: transparent;
  text-decoration: underline;
  color: rgb(101, 22, 14);
  color: var(--mainbg);
}

.sponsorPage_storyIcon__krBYb {
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  padding-right: 0.5rem;
}

.sponsorPage_sponsorshipPlan__1xfzP {
  color: #fcb040;
  color: var(--mainColor);
  text-align: start;
}

.sponsorPage_monthlyAmount__27A_y {
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 2rem;
  border-radius: 10px;
  color: #fff;
  color: var(--white);
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
}

form {
  padding-top: 2rem;
  padding-bottom: 5rem;
}

.sponsorPage_sectionHeader__2qksV {
  text-transform: uppercase;
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  letter-spacing: normal;
  font-weight: bold;
  text-align: center;
}

.sponsorPage_contactInformation__30xQ_ {
  padding: 2rem 5rem;
  text-align: start;
  margin-bottom: 2rem;
  text-align: center;
}

.sponsorPage_contactInformation__30xQ_:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.sponsorPage_isOrganisationHidden__duD4N {
  -webkit-animation: sponsorPage_isOrganisationAnimationHidden__1qivJ 1s;
          animation: sponsorPage_isOrganisationAnimationHidden__1qivJ 1s;
  display: none;
  width: 100%;
  box-sizing: border-box;
  transition: 0.5ss;
}

.sponsorPage_isOrganisationShow__3YvQ1 {
  display: block;
  transition: 0.5s;
  -webkit-animation: sponsorPage_isOrganisationAnimationShow__1m4nn 0.5s;
          animation: sponsorPage_isOrganisationAnimationShow__1m4nn 0.5s;
}

@-webkit-keyframes sponsorPage_isOrganisationAnimationHidden__1qivJ {
  0% {
    opacity: 1;
    height: -webkit-fit-content;
    height: fit-content;
  }

  50% {
    height: 0px;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes sponsorPage_isOrganisationAnimationHidden__1qivJ {
  0% {
    opacity: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  50% {
    height: 0px;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@-webkit-keyframes sponsorPage_isOrganisationAnimationShow__1m4nn {
  0% {
    opacity: 0;
  }

  80% {
    height: -webkit-fit-content;
    height: fit-content;
    opacity: 0;
  }
  100% {
    display: block;
  }
}

@keyframes sponsorPage_isOrganisationAnimationShow__1m4nn {
  0% {
    opacity: 0;
  }

  80% {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    opacity: 0;
  }
  100% {
    display: block;
  }
}

.sponsorPage_formInputs__FS6ZN {
  display: flex;
  flex-direction: column;
}

.sponsorPage_formInputs__FS6ZN label {
  text-align: start;
}
input,
select {
  height: 40px;
  border-radius: 8px;
  border: 2px solid #cecece;
  border: 2px solid var(--pale);
}

.sponsorPage_sponsorButton__21Bmy {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
}

.sponsorPage_sponsorButton__21Bmy:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
}

@media (max-width: 767.98px) {
  .sponsorPage_article__2pB4W {
    width: auto;
  }

  .sponsorPage_contactInformation__30xQ_ {
    padding: 2rem 1.5rem;
  }

  .sponsorPage_smallerMobile__36Xlm {
    width: 100%;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.addChild_addachildPage__PqtiO {
  background-color: #f3f0e9;
  padding-top: 5rem;
  text-align: center;
  top: 0;
}

.addChild_article__1WGL0 {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.addChild_childInformation__3jxC_ {
  padding: 2rem 5rem;
  background-color: #fff;
  background-color: var(--white);
  position: relative;
  margin-bottom: 2rem;
}

.addChild_childInformation__3jxC_:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

.addChild_sectionHeader__2rG9O {
  text-transform: uppercase;
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  letter-spacing: normal;
  font-weight: bold;
  text-align: center;
}

.addChild_formInputs__15D4n {
  display: flex;
  flex-direction: column;
  text-align: start;
}

.addChild_formInputs__15D4n label,
.addChild_formInputs__15D4n input {
  padding-left: 5px;
}

textarea {
  border-radius: 8px;
  border: 2px solid #cecece;
  border: 2px solid var(--pale);
}

.addChild_addChildButton__oTp7w {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
}

.addChild_addChildButton__oTp7w:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
}

@media (max-width: 991.98px) {
  .addChild_article__1WGL0 {
    max-width: 700px;
  }
}

@media (max-width: 767.98px) {
  .addChild_article__1WGL0 {
    max-width: 600px;
  }
  .addChild_childInformation__3jxC_ {
    padding: 2rem 3rem;
  }
}

@media (max-width: 575.98px) {
  .addChild_article__1WGL0 {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .addChild_childInformation__3jxC_ {
    padding: 2rem;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.hobbies_enteredHobbies__18sif {
  width: 100%;
  min-height: 50px;
  background-color: #285c463c;
  border-radius: 8px;
  padding: 1rem;
}

.hobbies_eachHobby__1sQgz {
  margin: 0.5rem;
  padding: 0.3rem;
  border-radius: 10px;
  font-size: 14px;
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  position: relative;
  background-color: #fff;
  background-color: var(--white);
  display: inline-block;
}

.hobbies_eachHobby__1sQgz button {
  border-radius: 50%;
  border: none;
  -webkit-transform: rotate(45deg) translateY(-50%);
          transform: rotate(45deg) translateY(-50%);
  transition: 0.3s;
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  color: #fff;
  color: var(--white);
  position: absolute;
  right: -2px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  width: 18px;
  padding: 0rem;
  line-height: 2px;
}
.hobbies_eachHobby__1sQgz button:hover {
  background-color: #3f916e;
}

.hobbies_addingHobby__2rSfJ {
  padding-top: 1rem;
}

.hobbies_addhobby__2w8P_ {
  border: none;
  margin-left: 5px;
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  border-radius: 8px;
  font-size: 14px;
  padding: 0.5rem 1rem;
  color: #fff;
  color: var(--white);
  transition: 0.3s;
}
.hobbies_addhobby__2w8P_:hover {
  background-color: #3f916e;
}

@media (max-width: 767.98px) {
  .hobbies_addhobby__2w8P_ {
    margin-top: 0.5rem;
  }
}

@media (max-width: 575.98px) {
  .hobbies_addingHobby__2rSfJ input {
    width: 100%;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --palebg: #f3f0e9;
}

.login_loginContainer__1dQgs {
  margin-top: 0px;
  background-color: #f3f0e9;
  background-color: var(--palebg);
  box-sizing: border-box;
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: calc(100vh - 142px);
}
.login_innerContent__39rPU {
  max-width: 500px;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  padding: 3rem;
  border-radius: 5px;
  background-color: #fff;
  background-color: var(--white);
  color: rgb(101, 22, 14);
  color: var(--mainbg);
  position: relative;
}

.login_innerContent__39rPU:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 7px;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(
    90deg,
    #e23505 4%,
    #db4b04 13.2857%,
    #c87005 29%,
    #db7f06 11.5714%,
    #cbbe06 36%,
    #a8d064 50.8571%,
    #a8d064 64%,
    #3cab1a 57.1428%,
    #3cab1a 67%,
    #008c15 71.4285%,
    #008c15 0,
    #00630f 85.7142%,
    #00630f 0
  );
}

h4 {
  text-align: center;
  font-weight: bold;
}

.login_formInputs__2AL8- {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

input {
  padding-left: 5px;
  height: 40px;
}

.login_loginButton__gTAhe,
.login_allChildren__2L2FW {
  padding: 0.8rem 2rem;
  background-color: #ca830e;
  color: #fff;
  border: 2px solid transparent;
  box-shadow: -5px -5px 15px rgb(255 255 255 / 90%),
    5px 5px 15px rgb(107 69 7 / 30%);
  border-radius: 50rem;
  border: none;
  transition: 0.3s;
  text-decoration: none;
  font-weight: bold;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.login_simpleLinks__1s7GI {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
}

.login_allChildren__2L2FW {
  background-color: rgba(101, 23, 14, 0.619);
  background-color: var(--mainbgLight);
}

.login_loginButton__gTAhe:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
}

.login_allChildren__2L2FW:hover {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  color: #fff;
  color: var(--white);
}

.login_connectLink__3RE99 {
  color: #fcb040;
  color: var(--mainColor);
  transition: 0.3s;
}

.login_connectLink__3RE99:hover {
  color: #fcb140a8;
  color: var(--mainColorLight);
}

@media (max-width: 767.98px) {
  .login_childCardImage__9oPFL {
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 575.98px) {
  .login_innerContent__39rPU {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
  --pale: #cecece;
  --dark: #010101;
}

.sponsorSite_main__3mD0p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: #010101;
  color: var(--dark);
  line-height: 1.5;
}

.sponsorSite_minNav__O7jjk {
  background-color: #fcb040;
  background-color: var(--mainColor);
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0rem 2rem;
}

#sponsorSite_nav2__ELY7s {
  display: none;
}

.sponsorSite_minNav__O7jjk a {
  display: inline-block;
  padding: 0.5rem 0.5rem;
  color: #fff;
  color: var(--white);
  opacity: 0.5;
  font-size: 1.2rem;
  transition: 0.25s;
}

.sponsorSite_minNav__O7jjk a:hover {
  opacity: 0.8;
}

:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}
.navbar_navbarMain__1lwMl {
  font-family: 'Inter', sans-serif;
  font-family: 'Questrial', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  position: -webkit-sticky;
  position: sticky;
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
  z-index: 400;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 4px 22px 2px rgb(35 6 6 / 50%);
  transition: 0.3s;
}
.navbar_navbar-toggler-icon__32u8w {
  color: #fff;
}

.navbar_fixedNav__2srWM {
  background-color: rgb(101, 22, 14);
  background-color: var(--mainbg);
}

.navbar_brandLogo__3gvO3 {
  height: 87px;
}

.navbar_myToggler__2XoFS {
  border: none;
  background-color: #fff;
  background-color: var(--white);
}

.navbar_myToggler__2XoFS:focus,
.navbar_myToggler__2XoFS:active {
  border: none;
  box-shadow: none;
}

.navbar_scrollableLinks__4DC44 {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: auto;
}

.navbar_navbarLinks__1vIMR {
  display: flex;
  justify-content: space-around;
  align-items: center;
  grid-column-gap: 0.25em;
  -webkit-column-gap: 0.25em;
          column-gap: 0.25em;
  box-sizing: border-box;
  min-height: 90px;
  font-size: 15px;
  font-family: Questrial;
  text-decoration: none;
  color: #fff !important;
  color: var(--white) !important;
  font-weight: bold;
  padding: 0px 14px;
  grid-gap: 20px;
  gap: 20px;
  margin-right: 1rem;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
#navbar_mydropDown__2yYmQ {
  color: #fff !important;
}
.navbar_navbarLinks__1vIMR:before {
  position: absolute;
  content: '';
  top: 99%;
  left: 50%;
  height: 5px;
  width: 0%;
  background-color: #fcb040;
  background-color: var(--mainColor);
  transition: 0.3s;
  opacity: 0;
}
.navbar_navbarLinks__1vIMR:after {
  position: absolute;
  content: '';
  top: 99%;
  right: 50%;
  height: 5px;
  width: 0%;
  background-color: #fcb040;
  background-color: var(--mainColor);
  transition: 0.3s;
  opacity: 0;
}

.navbar_navbarLinks__1vIMR:hover {
  color: #fcb040;
  color: var(--mainColor);
}
.navbar_navbarLinks__1vIMR:hover:before {
  width: 50%;
  opacity: 1;
}
.navbar_navbarLinks__1vIMR:hover:after {
  width: 50%;
  opacity: 1;
}

.navbar_navbarMain__1lwMl .navbar_donate__3ZQCU {
  padding: 0.8rem 1rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
}

.navbar_buttonIcon__1tyP7 {
  padding-left: 5px;
}

.navbar_navbarMain__1lwMl .navbar_donate__3ZQCU:hover {
  background-color: #fff;
  background-color: var(--white);
  color: rgb(101, 22, 14);
  color: var(--mainbg);
}

@media (max-width: 991.98px) {
  .navbar_navbarLinks__1vIMR {
    min-height: 60px;
    justify-content: flex-start;
  }
  .navbar_navbarMain__1lwMl .navbar_donate__3ZQCU {
    display: inline-block;
    margin-bottom: 1.5rem;
  }
  .navbar_navbarLinks__1vIMR::after {
    display: none;
    top: 100;
  }
  .navbar_navbarLinks__1vIMR::before {
    height: 3px;
    top: 70%;
    left: 0;
  }
}

