:root {
  --mainbg: rgb(101, 22, 14);
  --mainColor: #fcb040;
  --subColor: #25aae2;
  --mainbgLight: rgba(101, 23, 14, 0.619);
  --mainColorLight: #fcb140a8;
  --subColorLight: #e44245a1;
  --white: #fff;
}
.navbarMain {
  font-family: 'Inter', sans-serif;
  font-family: 'Questrial', sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  position: sticky;
  background-color: var(--mainbg);
  z-index: 400;
  box-sizing: border-box;
  padding-left: 3%;
  padding-right: 3%;
  box-shadow: 0px 4px 22px 2px rgb(35 6 6 / 50%);
  transition: 0.3s;
}
.navbar-toggler-icon {
  color: #fff;
}

.fixedNav {
  background-color: var(--mainbg);
}

.brandLogo {
  height: 87px;
}

.myToggler {
  border: none;
  background-color: var(--white);
}

.myToggler:focus,
.myToggler:active {
  border: none;
  box-shadow: none;
}

.scrollableLinks {
  height: fit-content;
  margin-left: auto;
}

.navbarLinks {
  display: flex;
  justify-content: space-around;
  align-items: center;
  column-gap: 0.25em;
  box-sizing: border-box;
  min-height: 90px;
  font-size: 15px;
  font-family: Questrial;
  text-decoration: none;
  color: var(--white) !important;
  font-weight: bold;
  padding: 0px 14px;
  gap: 20px;
  margin-right: 1rem;
  transition: 0.3s;
  position: relative;
  cursor: pointer;
}
#mydropDown {
  color: #fff !important;
}
.navbarLinks:before {
  position: absolute;
  content: '';
  top: 99%;
  left: 50%;
  height: 5px;
  width: 0%;
  background-color: var(--mainColor);
  transition: 0.3s;
  opacity: 0;
}
.navbarLinks:after {
  position: absolute;
  content: '';
  top: 99%;
  right: 50%;
  height: 5px;
  width: 0%;
  background-color: var(--mainColor);
  transition: 0.3s;
  opacity: 0;
}

.navbarLinks:hover {
  color: var(--mainColor);
}
.navbarLinks:hover:before {
  width: 50%;
  opacity: 1;
}
.navbarLinks:hover:after {
  width: 50%;
  opacity: 1;
}

.navbarMain .donate {
  padding: 0.8rem 1rem;
  color: #fff;
  border: 2px solid #fff;
  border-radius: 10px;
  transition: 0.3s;
  text-decoration: none;
  position: relative;
}

.buttonIcon {
  padding-left: 5px;
}

.navbarMain .donate:hover {
  background-color: var(--white);
  color: var(--mainbg);
}

@media (max-width: 991.98px) {
  .navbarLinks {
    min-height: 60px;
    justify-content: flex-start;
  }
  .navbarMain .donate {
    display: inline-block;
    margin-bottom: 1.5rem;
  }
  .navbarLinks::after {
    display: none;
    top: 100;
  }
  .navbarLinks::before {
    height: 3px;
    top: 70%;
    left: 0;
  }
}
